import React, { useState, useEffect } from "react"
import SEO from "components/globals/seo"
import Link from 'components/globals/link'

import Image404 from 'images/404.png'

export default () => {
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		if(typeof window !== 'undefined' && window.location.href.includes('MDW')){
			window.location.href = 'https://yourcreative.com.au/mdw2023'
		}

		setTimeout(() => {
			setIsMounted(true)
		},300)
	},[])

	return (
		<div className={`page-404 ${ isMounted ? 'mounted' : '' }`}>
			<SEO title="404: Not found" />
			<div data-scroll-section className={'container deep'}>
				<img alt={'404 image'} src={Image404} />
				<h1>
					Page not found
			</h1>
				<p>
					We can't find anything at this address. Perhaps head to the <Link className={'has-cursor-effect'} to='/'>home page</Link>, and try from there?
			</p>
			</div>
		</div>
	)
}